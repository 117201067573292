$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";@import 'lightbox-react/style.css';

@mixin categoryStyling {
  margin-bottom: 1rem;
  color: map-get($colors, medium-gray);
  font-family: map-get($fonts, pressrelease);
  font-size: rem(14px);
  text-transform: uppercase;
}

.cmp-photogrid__container {
  display: flex;
  flex-direction: column;
}

.cmp-photogrid__photos {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include media('>=desktop') {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }
}

.cmp-photogrid__number-shown {
  align-self: flex-end;
}

.cmp-photogrid__photo {
  margin-bottom: 0.5rem;
}

.cmp-photogrid__photo .cmp-photogrid__photo-media-video {
  width: 100%;
}

.cmp-photogrid__photo .cmp-photogrid__photo-media-img,
.cmp-photogrid__photo .cmp-photogrid__photo-media-video {
  margin: 0 auto;
  @include media('<=screenSmall') {
    width: 100%;
    height: auto;
  }
}

.cmp-photogrid__photo-media {
  @include media('>=desktop') {
    object-fit: cover;
    object-position: center;
    height: 18rem;
    width: 100%;
  }
}

.cmp-photogrid__content {
  display: flex;
  flex-direction: column;

  @include media('>=desktop') {
    flex-direction: row;
  }
}

.cmp-photogrid__filter {
  width: 100%;
  max-height: rem(250px);
  font-family: map-get($fonts, pressrelease);
  font-size: rem(12px);
  color: map-get($colors, medium-gray);
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;

  @include media('>=desktop') {
    min-width: rem(287);
    margin-right: 4rem;
  }
}

.cmp-photogrid__filter-category-container {
  position: relative;

  .cmp-photogrid__filter-form-arrow {
    position: absolute;
    width: 22px;
    height: 11px;
    top: 16px;
    right: 12px;
  }
}

.cmp-photogrid__filter-category {
  width: 100%;
  appearance: none;
  border: 1px solid map-get($borders, dark-border);
  border-radius: 0;
  font-family: map-get($fonts, pressrelease);
  font-size: rem(14px);
  color: map-get($colors, pressrelease-form);
  margin-bottom: rem(14);
  padding: rem(12px);
}

.cmp-photogrid__filter-clear {
  background: none;
  border: none;
  padding: 0 !important;
  font-family: map-get($fonts, pressrelease);
  font-size: rem(12px);
  color: map-get($colors, medium-gray);
  letter-spacing: 0.2px;
  cursor: pointer;
  align-self: flex-end;
  text-transform: uppercase;
}

.cmp-photogrid__photo-card {
  background-color: map-get($backgrounds, light-bg);
  padding: 0.5rem;
}

.cmp-photogrid__photo-card-icon {
  display: inline;
  position: relative;
  top: 0.4rem;
  $photo-card-icon-side-length: 1.4rem;
  height: $photo-card-icon-side-length;
  width: $photo-card-icon-side-length;
  transition: transform .3s ease-in-out;
}

.cmp-photogrid__photo-card-view-btn {
  border: none;
  background: none;
  color: map-get($colors, medium-gray);
  font-family: map-get($fonts, pressrelease);
  font-size: rem(14px);
  text-transform: uppercase;

  &:hover .cmp-photogrid__photo-card-icon {
    transform: translate(rem(10));
  }
}

.cmp-photogrid__photo-card-download-btn {
  text-decoration: none;
  color: map-get($colors, medium-gray);
  font-family: map-get($fonts, pressrelease);
  font-size: rem(14px);
  text-transform: uppercase;
}

.cmp-photogrid__photo-card-download-btn:hover {
  cursor: pointer;
}

.cmp-photogrid__photo-card-category {
  @include categoryStyling();
}

.cmp-photogrid__photo-card-view-download {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cmp-photogrid .slick-slide img,
.cmp-photogrid .slick-slide video {
  margin: 0 auto;
}

.cmp-photogrid .ril-image-current .ril__image {
  display: flex;
  justify-content: center;
}

.cmp-photogrid__lightbox-container {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  max-height: 80%;

  @include media('>=desktop') {
    max-width: 50%;
    margin: auto;
    margin-top: 4rem;
  }
}

.cmp-photogrid__lightbox-container img {
  display: block;
  margin: 0 auto;
  height: auto;
  width: 80%;

  @include media('>=desktop') {
    width: 50%;
  }
}

.cmp-photogrid__lightbox-metadata {
  display: flex;
  flex-direction: column;
  background: map-get($backgrounds, light-bg);
  margin: 0 auto;
  width: 80%;

  @include media('>=desktop') {
    width: 50%;
  }
}

video + .cmp-photogrid__lightbox-metadata {
  width: 100%;
}

.cmp-photogrid__lightbox-download {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cmp-photogrid__lightbox-category {
  @include categoryStyling();
}
